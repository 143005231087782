import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import CardList from "../components/common/CardList"
import { SEO } from "../components/seo"
import bannerImg from "../assets/images/hotel-service.jpg"
import Banner from "../components/common/Banner"

export const pageQuery = graphql`
  query HotelOperationsQuery {
    HotelOperations: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "hotel-operation-details" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            template
            title
            description
          }
        }
      }
    }
  }
`

const HotelOperations = ({ data }) => {
  const hotelOperations = [];
  const imgPath = process.env.GATSBY_IMAGE_URL
  data.HotelOperations.edges.forEach(edge => {
    hotelOperations.push({
      slug: edge.node.frontmatter.slug,
      title: edge.node.frontmatter.title,
      description: edge.node.frontmatter.description,
      image: `${imgPath}${edge.node.frontmatter.slug.toLowerCase()}.jpg`
    })
  });
  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Our Services', link: '/our-services' },
    { name: 'Hotel Services', link: '' }
  ]
  return (
    <Layout>
      <Banner
        data="Hotel Services"
        image={bannerImg}
        crumbs={crumbs}
      />
      <CardList data={hotelOperations} />
    </Layout>
  )
};

export default HotelOperations
export const Head = () => (
  <SEO
    title="Hotel Services - Housefull Events & Hospitality Management"
    description="Experience exceptional hospitality/ hotel services that cater to your every need. Enjoy luxurious accommodations, exquisite dining and impeccable service."
  />
)